import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"


const Details = ({ data }) => {
  return (
    <div className="uk-section-default uk-section uk-section-large" uk-scrollspy="target: [uk-scrollspy-class]; cls: uk-animation-fade; delay: false;">

      <div className="uk-container">

        <StaticQuery
          query={graphql`
              query  {
                site {
                  siteMetadata {
                    title
                    author
                  }
                }
                founder:strapiAbouts(slug: {eq: "man-behind"}) {
                  image {
                    childImageSharp {
                      fixed(width: 300, height: 300,toFormat: JPG) {
                        src
                        height
                        width
                      }
                    }
                  }
                  title
                  excerpt
                }
                moto:strapiAbouts(slug: {eq: "moto-minuto"}) {
                  content
                  title
                  excerpt
                }
                vision:strapiAbouts(slug: {eq: "vision"}) {
                  content
                  title
                  excerpt
                }
                mission:strapiAbouts(slug: {eq: "mission"}) {
                  content
                  title
                  excerpt
                }

              }

            `}
          render={({ founder, moto, vision, mission }) => {
            return (
              <div className="uk-grid-large uk-grid-margin-large uk-grid" uk-grid="">
                <div className="uk-flex-auto uk-width-1-3@m uk-first-column">

                  <div className="uk-text-meta uk-margin-small uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" >The Man Behind</div>
                  <h2 className="uk-h1 uk-margin-remove-top uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" >        {founder.title}   </h2>
                  <div className="uk-margin uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" >
                    <img className="el-image"
                      alt=""
                      src={founder.image.childImageSharp.fixed.src}
                    />
                  </div>
                  <div className="uk-text-lead uk-margin-medium uk-margin-remove-top uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" >
                    {founder.content}
                  </div>
                  <h3 className="uk-h6 uk-margin-small uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" >
                    What he does
                   </h3>
                  <div className="uk-text-meta uk-margin-medium uk-margin-remove-top uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" >
                    <ReactMarkdown source={founder.excerpt} />
                  </div>
                  {/* <h3 className="uk-h6 uk-margin-small uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" >        Birthdate    </h3>
                  <div className="uk-text-meta uk-margin-small uk-margin-remove-top uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" >March 15, 1985</div>
                  <h3 className="uk-h6 uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" >        Follow me    </h3>
                  <div className="uk-margin uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" >
                    <div className="uk-child-width-auto uk-grid-medium uk-grid" uk-grid="">
                      <div className="uk-first-column">
                        <a className="el-link uk-icon-link uk-icon" href="https://twitter.com/" uk-icon="icon: twitter; ratio: 1;">
                          <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="twitter">
                            <path d="M19,4.74 C18.339,5.029 17.626,5.229 16.881,5.32 C17.644,4.86 18.227,4.139 18.503,3.28 C17.79,3.7 17.001,4.009 16.159,4.17 C15.485,3.45 14.526,3 13.464,3 C11.423,3 9.771,4.66 9.771,6.7 C9.771,6.99 9.804,7.269 9.868,7.539 C6.795,7.38 4.076,5.919 2.254,3.679 C1.936,4.219 1.754,4.86 1.754,5.539 C1.754,6.82 2.405,7.95 3.397,8.61 C2.79,8.589 2.22,8.429 1.723,8.149 L1.723,8.189 C1.723,9.978 2.997,11.478 4.686,11.82 C4.376,11.899 4.049,11.939 3.713,11.939 C3.475,11.939 3.245,11.919 3.018,11.88 C3.49,13.349 4.852,14.419 6.469,14.449 C5.205,15.429 3.612,16.019 1.882,16.019 C1.583,16.019 1.29,16.009 1,15.969 C2.635,17.019 4.576,17.629 6.662,17.629 C13.454,17.629 17.17,12 17.17,7.129 C17.17,6.969 17.166,6.809 17.157,6.649 C17.879,6.129 18.504,5.478 19,4.74"></path>
                          </svg>
                        </a>
                      </div>
                      <div>
                        <a className="el-link uk-icon-link uk-icon" href="https://pinterest.com/" uk-icon="icon: pinterest; ratio: 1;">
                          <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="pinterest">
                            <path d="M10.21,1 C5.5,1 3,4.16 3,7.61 C3,9.21 3.85,11.2 5.22,11.84 C5.43,11.94 5.54,11.89 5.58,11.69 C5.62,11.54 5.8,10.8 5.88,10.45 C5.91,10.34 5.89,10.24 5.8,10.14 C5.36,9.59 5,8.58 5,7.65 C5,5.24 6.82,2.91 9.93,2.91 C12.61,2.91 14.49,4.74 14.49,7.35 C14.49,10.3 13,12.35 11.06,12.35 C9.99,12.35 9.19,11.47 9.44,10.38 C9.75,9.08 10.35,7.68 10.35,6.75 C10.35,5.91 9.9,5.21 8.97,5.21 C7.87,5.21 6.99,6.34 6.99,7.86 C6.99,8.83 7.32,9.48 7.32,9.48 C7.32,9.48 6.24,14.06 6.04,14.91 C5.7,16.35 6.08,18.7 6.12,18.9 C6.14,19.01 6.26,19.05 6.33,18.95 C6.44,18.81 7.74,16.85 8.11,15.44 C8.24,14.93 8.79,12.84 8.79,12.84 C9.15,13.52 10.19,14.09 11.29,14.09 C14.58,14.09 16.96,11.06 16.96,7.3 C16.94,3.7 14,1 10.21,1"></path>
                          </svg>
                        </a>
                      </div>
                      <div>
                        <a className="el-link uk-icon-link uk-icon" href="https://facebook.com/" uk-icon="icon: facebook; ratio: 1;">
                          <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="facebook">
                            <path d="M11,10h2.6l0.4-3H11V5.3c0-0.9,0.2-1.5,1.5-1.5H14V1.1c-0.3,0-1-0.1-2.1-0.1C9.6,1,8,2.4,8,5v2H5.5v3H8v8h3V10z"></path>
                          </svg>
                        </a>
                      </div>

                    </div>
                  </div> */}

                </div>

                <div className="uk-flex-auto uk-width-2-3@m">

                  <div className="uk-text-meta uk-margin-small uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" >EXCERPT</div>
                  <h3 className="uk-h1 uk-margin-small uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" >{moto.title}</h3>
                  <div className="uk-text-large uk-margin uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" >{moto.content}</div>
                  {/* <div className="uk-margin uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</div> */}
                  {/* <ul className="uk-list uk-margin-medium uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" >
              <li className="el-item">
                <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-middle uk-grid" uk-grid="">
                  <div className="uk-width-auto uk-first-column"><span className="el-image uk-icon" uk-icon="icon: check;"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="check"><polyline fill="none" stroke="#000" stroke-width="1.1" points="4,10 8,15 17,4"></polyline></svg></span></div>
                  <div>
                    <div className="el-content uk-panel">Web Design</div>
                  </div>
                </div>
              </li>
              <li className="el-item">
                <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-middle uk-grid" uk-grid="">
                  <div className="uk-width-auto uk-first-column"><span className="el-image uk-icon" uk-icon="icon: check;"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="check"><polyline fill="none" stroke="#000" stroke-width="1.1" points="4,10 8,15 17,4"></polyline></svg></span></div>
                  <div>
                    <div className="el-content uk-panel">Brand Styles Guides</div>
                  </div>
                </div>
              </li>
              <li className="el-item">
                <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-middle uk-grid" uk-grid="">
                  <div className="uk-width-auto uk-first-column"><span className="el-image uk-icon" uk-icon="icon: check;"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="check"><polyline fill="none" stroke="#000" stroke-width="1.1" points="4,10 8,15 17,4"></polyline></svg></span></div>
                  <div>
                    <div className="el-content uk-panel">Print Design</div>
                  </div>
                </div>
              </li>
              <li className="el-item">
                <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-middle uk-grid" uk-grid="">
                  <div className="uk-width-auto uk-first-column"><span className="el-image uk-icon" uk-icon="icon: check;"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="check"><polyline fill="none" stroke="#000" stroke-width="1.1" points="4,10 8,15 17,4"></polyline></svg></span></div>
                  <div>
                    <div className="el-content uk-panel">Photography</div>
                  </div>
                </div>
              </li>
            </ul> */}
                  <hr className="uk-margin-medium uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" />
                  <div className="uk-text-meta uk-margin-small uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" >-</div>
                  <h3 className="uk-h1 uk-margin-small uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" >{mission.title}</h3>
                  <div className="uk-text-large uk-margin uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" >
                    {mission.content}
                  </div>
                  <div className="uk-margin uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="">{mission.excerpt}</div>
                  {/* <ul className="uk-list uk-margin-medium uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" >
              <li className="el-item">
                <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-middle uk-grid" uk-grid="">
                  <div className="uk-width-auto uk-first-column">
                    <span className="el-image uk-icon" uk-icon="icon: check;"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="check"><polyline fill="none" stroke="#000" stroke-width="1.1" points="4,10 8,15 17,4"></polyline></svg></span>
                  </div>
                  <div>
                    <div className="el-content uk-panel">Branding &amp; Identity</div>
                  </div>
                </div>
              </li>
              <li className="el-item">
                <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-middle uk-grid" uk-grid="">
                  <div className="uk-width-auto uk-first-column">
                    <span className="el-image uk-icon" uk-icon="icon: check;"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="check"><polyline fill="none" stroke="#000" stroke-width="1.1" points="4,10 8,15 17,4"></polyline></svg></span>
                  </div>
                  <div>
                    <div className="el-content uk-panel">Web Design</div>
                  </div>
                </div>
              </li>
              <li className="el-item">
                <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-middle uk-grid" uk-grid="">
                  <div className="uk-width-auto uk-first-column">
                    <span className="el-image uk-icon" uk-icon="icon: check;"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="check"><polyline fill="none" stroke="#000" stroke-width="1.1" points="4,10 8,15 17,4"></polyline></svg></span>
                  </div>
                  <div>
                    <div className="el-content uk-panel">Mobile Design</div>
                  </div>
                </div>
              </li>
              <li className="el-item">
                <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-middle uk-grid" uk-grid="">
                  <div className="uk-width-auto uk-first-column">
                    < span className="el-image uk-icon" uk-icon="icon: check;">
                      <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="check">
                        <polyline fill="none" stroke="#000" stroke-width="1.1" points="4,10 8,15 17,4"></polyline>
                      </svg>
                    </span>
                  </div>
                  <div>
                    <div className="el-content uk-panel">Copywriting</div>
                  </div>
                </div>
              </li>
            </ul> */}
                  <hr className="uk-margin-medium uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" />
                  <div className="uk-text-meta uk-margin-small uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" >-</div>
                  <h3 className="uk-h1 uk-margin-small uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" >        {vision.title}    </h3>
                  <div className="uk-text-large uk-margin uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" >
                    {vision.content}
                  </div>
                  <div className="uk-margin uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="">{vision.excerpt}</div>
                  {/* <ul className="uk-list uk-margin-medium uk-scrollspy-inview uk-animation-fade" uk-scrollspy-className="" >
              <li className="el-item">
                <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-middle uk-grid" uk-grid="">
                  <div className="uk-width-auto uk-first-column">
                    <span className="el-image uk-icon" uk-icon="icon: check;"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="check"><polyline fill="none" stroke="#000" stroke-width="1.1" points="4,10 8,15 17,4"></polyline></svg></span>
                  </div>
                  <div>
                    <div className="el-content uk-panel">Brand Development</div>
                  </div>
                </div>
              </li>
              <li className="el-item">
                <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-middle uk-grid" uk-grid="">
                  <div className="uk-width-auto uk-first-column">
                    <span className="el-image uk-icon" uk-icon="icon: check;"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="check"><polyline fill="none" stroke="#000" stroke-width="1.1" points="4,10 8,15 17,4"></polyline></svg></span>
                  </div>
                  <div>
                    <div className="el-content uk-panel">Illustration</div>
                  </div>
                </div>
              </li>
              <li className="el-item">
                <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-middle uk-grid" uk-grid="">
                  <div className="uk-width-auto uk-first-column">
                    <span className="el-image uk-icon" uk-icon="icon: check;"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="check"><polyline fill="none" stroke="#000" stroke-width="1.1" points="4,10 8,15 17,4"></polyline></svg></span>
                  </div>
                  <div>
                    <div className="el-content uk-panel">Studio Work</div>
                  </div>
                </div>
              </li>
              <li className="el-item">
                <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-middle uk-grid" uk-grid="">
                  <div className="uk-width-auto uk-first-column">
                    <span className="el-image uk-icon" uk-icon="icon: check;"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="check"><polyline fill="none" stroke="#000" stroke-width="1.1" points="4,10 8,15 17,4"></polyline></svg></span>
                  </div>
                  <div>
                    <div className="el-content uk-panel">Methodology</div>
                  </div>
                </div>
              </li>
            </ul> */}

                </div>
              </div>
            )
          }
          }
        />
      </div>

    </div>

  )
}

export default Details




