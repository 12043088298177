import React from "react"
import PropTypes from "prop-types"

import Nav from "../nav"
import Footer from "../footer"
import Seo from "../seo"

import Details from "./about-details"
const Layout = ({ children }) => {
  return (
    <>
      <Seo />
      <div className="head-banner-wrap">
        <Nav />
      </div>
      <Details />
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout  